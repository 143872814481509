.uploadImageSection {
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  &__imagesWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem;
  }
  .errorMessage {
    p {
      margin: 0;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8d7d9;
    color: #88393f;
    padding: 0.3rem 1rem;
    column-gap: 0.5rem;
    border: 2px solid #f5c6ca;
    border-radius: 10px;
    width: fit-content;
    margin: 2rem auto;
  }
}
