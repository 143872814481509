@import '/base.scss';
.container {
  &__inputSection {
    margin-bottom: 1rem;
    input.ant-input {
      font-size: 1rem;
      border-bottom: 2px solid #e8e8e8;
      outline: none;
      padding: 0.1rem;
      &:focus {
        border-bottom: 2px solid $base-color;
        outline: none;
        border-top: none;
        border-left: none;
        border-right: none;
      }
    }
  }
  &__nextButton {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}
.collapse__container {
  margin: 2rem 0 1rem;
  border: 2px solid #e8e8e8;
  border-radius: 10px;
  .ant-collapse-item.detail-panel {
    padding: 0;
  }
}
