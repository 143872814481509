.InfoCard {
  font-family: 'CenturyGothic';
  background-color: #fff;
  color: #030303;
  display: grid;
  grid-template-columns: 1fr;
  width: 375px;

  .card-box {
    background-color: #fff;
    padding: 16px;
    .title-heading {
      font-family: 'CenturyGothicBold';
      font-size: 20px;
      font-weight: bold;
      color: #333;
    }

    .content-box {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      font-size: 13px;
      align-items: center;
      justify-content: center;
      width: 100%;

      .content-data {
        background-color: #fff;
        background-size: cover;
        background-position: center;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 0 16px;
        height: 70px;
        font-family: 'Lato';
        border-radius: 16px;
        box-shadow: 0px 2px 8px 0px rgba(222, 222, 222, 0.5);

        .card-text {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .card-icon {
          position: absolute;
          width: 18px;
          height: 18px;
          top: 7px;
          right: 7px;
        }
      }

      .purple {
        background-image: url('./assets/purple.png');
      }

      .blue {
        background-image: url('./assets/blue.png');
      }

      .red {
        background-image: url('./assets/red.png');
      }

      .orange {
        background-image: url('./assets/orange.png');
      }

      .yellow {
        background-image: url('./assets/yellow.png');
      }
    }

    .card-data {
      height: 180px;
      background-color: #fff;
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      font-family: 'CenturyGothicBold';
      font-size: 16px;
      font-weight: bold;
      border-radius: 18px;
      box-shadow: 0px 2px 8px 0px rgba(222, 222, 222, 0.5);

      .card-text {
        padding: 0px 16px;
        position: absolute;
        width: 80%;
        z-index: 2;
      }

      .card-icon {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 16px;
        right: 16px;
        z-index: 3;
      }

      .card-img {
        display: block;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        bottom: 0px;
        z-index: 1;
      }
    }
  }

  .img-box {
    background-color: #fff;
    padding: 16px 0px;
    // width: 50%;
    border-radius: 8px;
  }

  .img-box-2 {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }

  .btn-box {
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: #fff;
    padding: 16px 16px 150px;

    .multi-btn {
      display: flex;
      align-content: center;
      justify-content: space-evenly;
      background-color: #fff;
      flex-direction: row;
      width: 250px;
    }

    .block-btn {
      background-color: #ff5b91;
      color: #fff;
      margin-top: 10px;
      width: 200px;
    }
  }
}

.ImageCard {
  // border: 1px solid blue;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px;
  overflow: hidden;
  .main-img {
    width: 100%;
    position: absolute;
    margin: 0;
    padding: 0;
    transform: scale(1.5);
  }
  .highlight {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5) 40%,
      rgba(0, 0, 0, 0.8) 100%
    );
    position: absolute;
    width: 100%;
    padding: 75px 20px 30px;
    bottom: 0px;
    font-family: 'CenturyGothic';
    color: #fff;

    .heading {
      font-size: 26px;
      font-weight: bold;
      font-family: 'CenturyGothicBold';
      text-shadow: 0px 2px 8px rbga(47, 47, 47, 0.5);
      padding-bottom: 8px;
    }
    .content {
      // border: 1px solid red;
      font-family: 'Lato';
      display: flex;
      align-items: center;
      //   justify-content: center;
      flex-direction: row;
      font-size: 16px;
      padding-bottom: 4px;
      text-shadow: 0px 2px 8px rbga(47, 47, 47, 0.5);
      .img-box {
        // border: 1px solid blue;
        // box-shadow: 0px 2px 8px 0px red;
        width: 16px;
        height: 16px;
        margin: 3px 8px 0px 0px;
        padding: 16px;
        border-radius: 8px;
      }
    }
  }
}

.carousel-inner {
  overflow-y: auto;
  margin: auto;
  width: 100%;
  height: 100%;
}

.matchCard {
  position: relative;
  height: 100%;
}

.carousel-item {
  //   width: 35%;
  overflow: overlay;
}

.scroll {
  margin: 20px 10px;
  padding: 4px;
  width: 500px;
  // height: 50vh;
  overflow-x: hidden;
  overflow-x: auto;
  text-align: justify;
  border: 1px solid grey;
}

.carousel {
  position: relative;
  background: #fff;
  overflow: hidden;
  height: 100vh;
  //   width: 50%;
}

.carousel-inner {
  overflow-y: auto;
  margin: auto;
  width: 100%;
  height: 100%;
}

.MyMatches {
  font-family: 'CenturyGothic';
  background-color: #fff;
  color: #333;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 700px;
  z-index: 3;
  margin: 20px;
  border-radius: 8px;
}

.otherImage {
  width: 100%;
  padding: 16px;
  transform: scale(1.1);
}

.ant-select-lg {
  .ant-select-selection--multiple {
    min-height: 30px;
    height: auto;
    .ant-select-selection__rendered {
      min-height: 30px;
      height: 32px;
      li {
        height: 24px;
        line-height: 20px;
      }
    }
  }
}

.DataCards {
  border-color: #333;
  flex-direction: row;
  justify-content: space-between;
  border: 0.5px solid #e8e8e8;
  padding: 24px;
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 16px;
  display: flex;
}

.Datas {
  flex-direction: 'row';
  display: flex;
  justify-content: space-between;
  width: 25%;
}

.Content-DataView {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;

  overflow-y: auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.RadioSales {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
}

.BorderDataPP {
  border-color: #333;
  border: 0.5px solid #e8e8e8;
  padding: 16px;
  margin-top: 16px;
  border-radius: 16px;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .BorderData {
    border-color: #333;
    margin-top: 16px;
    border: 0.5px solid #e8e8e8;
    margin-left: 20px;
    border-radius: 16px;
    overflow: hidden;
  }

  .TableTitle {
    margin-left: 20px;
  }
}

@media (min-width: 900px) {
  .BorderData {
    border-color: #333;
    margin-top: 16px;
    border: 0.5px solid #e8e8e8;
    border-radius: 16px;
    overflow: hidden;
  }
}

// ----------------
