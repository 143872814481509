.Main {
  height: 100vh;
}
.Main .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.Main .logo-block {
  justify-content: center;
  align-items: center;
  display: flex;
}
.Main .logo {
  height: 25px;
  margin: 16px;
}
.Main .Content {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  overflow-y: auto;
}
