.ShortlistedUser {
  border: #e8e8e8 solid 1px;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
  padding: 20px;

  &__User {
    display: flex;
    flex-direction: row;
  }

  &__UserImage {
    border-radius: 14px;
    width: 140px;
    height: 200px;
    object-fit: cover;
  }

  &__Details {
    margin-left: 20px;
    &_Title {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.65);
      font-size: 16px;
      margin-bottom: 0px;
    }
    &_SubTitle {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      font-size: 16px;
      margin-bottom: 0px;
    }
    &_DarkSubTitle {
      margin-top: 10px;
      font-weight: 600;
      color: #333;
      font-size: 16px;
      margin-bottom: 0px;
    }
  }

  &__Seen {
    display: inherit;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: #ffeef4;
    &_Text {
      padding: 5px 22px 5px 22px;
      color: #ff5b91;
      font-weight: bold;
    }
  }

  &__Status_Update {
    margin-top: 30px;
    display: inherit;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: #ffeef4;
    &_Text {
      text-align: center;
      padding: 5px 22px 5px 22px;
      color: #ff5b91;
      font-weight: bold;
    }
  }

  &__Connected {
    display: inherit;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin-top: 16px;
    background-color: rgb(255, 250, 211);
    &_Text {
      padding: 5px 22px 5px 22px;
      color: rgb(250, 106, 63);
      font-weight: bold;
      margin-bottom: 0;
    }
  }
}

.Text {
  margin-left: 12px;
}

.Pinktext {
  color: #ff5b91;
  margin-left: 8px;
  margin-right: 8px;
  font-weight: bold;
  font-size: 18px;
}

.WithIcon {
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
}

.Cursor {
  cursor: pointer;
}

.Purple {
  background-color: #f5efff;
}

.PurpleText {
  color: #ba8ae0;
}

.GoldBorder {
  border: #f6aa00 solid 2px;
}

.PinkBorder {
  border: #ff5b91 solid 2px;
}

.Pink {
  background-color: #ff5b91;
}

.WhiteText {
  color: white;
}
