.selected-items-container {
  margin-top: 20px;
  p {
    margin: 0 0 10px;
    font-weight: bold;
    & ~ div {
      display: grid;
      grid-template-columns: repeat(4, 25%);
      gap: 15px 5px;
      .delete-item-button {
        width: 100%;
        &:hover {
          color: #333333;
          border-color: #d9d9d9;
        }
      }
    }
  }
}
