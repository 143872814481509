.UserImg {
  object-fit: cover;
  width: 420px;
  height: 420px;
}

.IdImg {
  object-fit: contain;
  width: 420px;
  height: 420px;
}

.ant-row > div {
  width: 100% !important;
  max-width: 100% !important;
}

.images-container {
  display: flex;
}
