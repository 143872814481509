.createProfile {
  border-radius: 0.6rem;
  background-color: #fff;
  width: 100%;
  margin: 1rem;
  &__wrapper {
    margin: 1rem auto;
    border-radius: 1rem;
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
  }
}
