.SearchUser .ant-input-affix-wrapper {
  border-radius: 40px;
}
.SearchUser .ant-select-selector {
  border-radius: 40px !important;
  overflow: hidden;
}
.SearchUser .ant-picker {
  border-radius: 40px;
}
.SearchUser .ant-select-multiple .ant-select-selector {
  border-radius: 0px !important;
}
.SearchUser .ant-select-single {
  border-radius: 40px;
}
.SearchUser .ant-select-selection__choice {
  border-radius: 40px !important;
}
.SearchUser .ant-select-lg .ant-select-selection--multiple {
  min-height: 40px;
  height: auto;
}
.SearchUser .ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered {
  min-height: 42px;
  height: 34px;
}
.SearchUser .ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 34px;
  line-height: 30px;
}
.div-age-slider {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.div-age-slider span {
  background-color: '#fff';
  margin-bottom: 4px;
  padding-left: 10px;
  font-size: 16px;
  color: #484444;
  flex-basis: 30%;
}
.slider-css {
  flex-basis: 50%;
  padding-top: 5px;
}
.crossMarkAgeSlider {
  margin-left: 20px;
  margin-bottom: 15px;
  border-radius: 50%;
  border: 0px;
  width: 16px;
  height: 16px;
  font-size: 8px;
  color: white;
  cursor: pointer;
}
