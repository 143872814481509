.ant-collapse.details-collapsible-container {
  background-color: transparent;
  .anticon.expandIcon-inner-panel {
    color: #ff8ab5;
    font-size: 1rem;
  }
  .ant-collapse-item.detail-panel {
    background: white;
  }
}

.detail-panel-header-title {
  font-size: 20px;
  font-family: 'Lato';
  font-weight: 700;
  margin: 0;
}

.detail-panel-header-subtitle {
  margin: 0;
  font-weight: 400;
  font-family: 'Lato';
  font-size: 18px;
  color: #333333;
  &.placeholder {
    color: #cccccc;
  }
}
