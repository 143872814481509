.tabbar-container {
  display: flex;
  background-color: white;
  border-radius: 2rem;
  padding: 5px;

  .tabbar-item {
    all: unset;
    flex-basis: 100%;
    padding: 0.5rem 0;
    text-align: center;
    font-family: 'Lato';
    font-weight: 700;
    cursor: pointer;
    &.active {
      background-color: #ff5b91;
      color: white;
      border-radius: 2rem;
    }
  }
}
