@import '/base.scss';

.modalStyle2 .ant-modal-content,
.modalStyle2 .ant-modal-body {
  background: white;
  box-shadow: none;
  border-radius: 5%;
}
.modal__button__container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0 0;
  button {
    box-shadow: $box-shadow;
  }
}
.dob {
  &__wrapper {
    margin: 2rem 0rem 0.5rem;
    h2 {
      margin-bottom: 0;
    }
  }
  &__inner {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 1rem;
  }
}
.add-date {
  width: auto;
}

.dob__wrapper-errorMessage {
  padding: 0.5rem;
}
