.ratingWrapper {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 99;

  &__toggleButton {
    width: 60px;
    height: 60px;
    padding: 4px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 5px 20px 1px;
    cursor: pointer;
    border: #f6aa00 solid 2px;
    font-size: 22px;
    font-weight: bold;
    display: grid;
    place-items: center;
  }

  &__star {
    position: absolute;
    top: 0px;
    right: -5px;
  }
  &__elements-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
  }
  &__element {
    border: #f6aa00 solid 2px;
    color: #f6aa00;
    width: 48px;
    height: 48px;
    padding: 4px;
    border-radius: 50%;
    // background-color: white;
    background: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #f5f5f5 100%);
    margin: 5px 0;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);

    cursor: pointer;
    &:hover {
      background: radial-gradient(50% 50% at 30% 30%, #f6aa00 0%, #f6aa00 100%);
      border: #fff solid 2px;
      color: white;
    }
  }
}
