.verifyModal {
  &__wrapper {
  }
  &__modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
