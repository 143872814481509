.option-pill-button {
  width: 100%;
  border: 1px solid #f5f5f5;
  font-size: 15px;
  color: #333333;
  font-family: 'Lato';
  font-weight: 400;
  padding: 0 10px;
  height: 40px;

  // overriding the default active and focus styles coming from antd
  &:active,
  &:focus {
    border: 1px solid #f5f5f5;
    color: #333333;
  }
  &:hover,
  &.selected {
    background: #ff5b91;
    border: 0;
    color: white;
  }
}
