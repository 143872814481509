.preference {
  &__container {
    display: flex;
    // justify-content: space-between;
    column-gap: 20px;
  }
  &__pill {
    width: 24%;
  }
}
