@import '/base.scss';

.calendar {
  padding: 0.5rem;
  background: #ffffff;
  border-radius: 1rem;
  height: 400px;
  width: 360px;
  margin: 0 auto;
  border: 1px solid #e8e8e8;

  button {
    background: white;
    border: 0;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      color: $base-color;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    margin-bottom: 0.2rem;
    border: 0.3px solid #e8e8e8;
    border-radius: 0.7rem;
    button {
      font-size: 1.1rem;
      padding-left: 0.5px;
    }
  }

  &__body {
    padding: 0rem 1rem;
    h2 {
      padding-left: 0.5rem;
    }
    .button {
      border-radius: 0.5rem;
      width: auto;
      background: transparent;
      &:hover {
        background-color: $base-color;
        color: #fff;
      }
      &__select-date {
        padding: 0.5rem;
        &.selected {
          border: 2px solid $base-color;
          color: $base-color;
          &:hover {
            color: #fff;
            
          }
        }
      }
    }
  }
}

.text-primaryPink {
  color: $base-color;
}

.text-black {
  color: #000000;
}

.text-silver {
  color: #c0c0c0;
}

.calendar__day {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0.3rem;
}
.calendar__month {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.3rem;
}
.calendar__year {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.3em;

  height: 280px;
  overflow-y: scroll;
}
