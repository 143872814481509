.mainContainer {
  padding: 1.5rem;
  p {
    margin: 0;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .input {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
    width: 50%;
  }
  .buttonContainer {
    text-align: center;
    margin-top: 1rem;
  }
}

.dropdown {
  width: 250px;
}

.errorMessage {
  p {
    margin: 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8d7d9;
  color: #88393f;
  padding: 0.3rem 1rem;
  column-gap: 0.5rem;
  border: 2px solid #f5c6ca;
  border-radius: 10px;
  width: 100%;
  margin: 1rem auto 0;
}
