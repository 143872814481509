.search-title {
  font-family: 'Lato';
  font-weight: 700;
}

.search-input {
  width: 70%;
}

.search-list {
  margin-top: 1rem;
  .search-list-item {
    padding: 0;
    display: block;
    color: #333333;
    &:hover {
      opacity: 0.7;
    }
  }
  .search-list-add-new {
    padding: 0;
    color: #ff5b91;
  }
  .search-list-cta {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}
