.partner-preference-container {
  background-color: #f5f5f5;
  overflow: hidden;
  .tab-pane-container {
    margin-top: 1.5rem;
  }
  .partner-preference-button-container {
    text-align: center;
  }
}
