.settleDown {
  &__container {
    display: flex;
    justify-content: space-between;
    // column-gap: 10px;
  }
  &__pill {
    width: 24%;
  }
}
