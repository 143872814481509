.container {
  height: 100%;
  position: relative;
  padding: 1.25rem 1.5rem;

  .accordion-container {
    width: 90%;
  }

  .anticon.closeIcon {
    position: absolute;
    top: 24px;
    right: 24px;
    font-size: 32px;
    color: #ff5b91;
    cursor: pointer;
  }
}
