@import '/base.scss';
.section__container-horizontal {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  gap: 15px 5px;
  .section__pill-horizontal {
    width: 100%;
  }
}
.section__container-vertical {
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
  align-items: flex-start;
  justify-content: flex-start;
  .section__pill-vertical {
    width: auto;
    background-color: white;
    border: none;
    cursor: pointer;

    &:hover {
      color: $base-color;
    }
  }
}
