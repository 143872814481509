@media screen and (min-width: 320px) {
  .HeroSection .c-container {
    background: url('../../assets/bg-mobile.png');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    height: 100vh;
    overflow: hidden;
    font-family: 'CenturyGothicBold';
    font-weight: bold;
    color: #ffffff;
    padding: 0px 15px 100px;
    text-align: center;
  }
  .HeroSection .c-container .logo {
    position: absolute;
    top: 50px;
    z-index: 1;
    text-align: center;
    max-width: 180px;
  }
  .HeroSection .c-container .c-heading {
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    font-size: 40px;
    color: #ffffff;
  }
  .HeroSection .c-container .c-sub-heading {
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    font-size: 24px;
    padding: 20px 0px 30px;
  }
  .HeroSection .c-container .c-sub-heading1 {
    font-family: 'Lato';
    text-align: center;
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    font-size: 10px;
    padding: 24px 0px 0px;
  }
  .HeroSection .c-container .phone-input {
    border-radius: 10px;
    width: 300px;
    text-align: center;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    font-size: 22px;
    font-family: 'CenturyGothicBold';
    background-color: #fff;
    font-weight: bold;
    height: 52px;
  }
  .HeroSection .c-container .phone-input:hover {
    border-width: 0px;
  }
  .HeroSection .c-container .phone-input:focus {
    border-width: 0px;
  }
  .HeroSection .c-container .phone-input .ant-input {
    border-radius: 10px;
    border: 0px;
  }
  .HeroSection .c-container .otp-input {
    width: 52px !important;
    height: 52px;
    border-radius: 10px;
    border: 0px;
    color: black;
  }
  .HeroSection .c-container .otp-input:focus {
    outline: 0px;
  }
  .HeroSection .c-container .separator {
    width: 2rem;
    font-size: 18px;
  }
  .HeroSection .c-container .btn {
    width: 300px;
    text-align: center;
    border-radius: 10px;
    border-width: 0px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    font-family: 'CenturyGothicBold';
    background-color: #ff78a5;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .HeroSection .c-container .btn:active {
    opacity: 1;
  }
}
@media screen and (min-width: 992px) {
  .HeroSection .c-container {
    background: url('../../assets/bg-desktop.png');
    background-size: cover;
    background-position: center;
    padding: 0 30px 100px;
  }
  .HeroSection .c-container .logo {
    position: absolute;
    top: 30px;
    left: 30px;
    text-align: left;
    max-width: 180px;
  }
  .HeroSection .c-container .c-heading {
    font-size: 40px;
    color: #ffffff;
  }
  .HeroSection .c-container .c-sub-heading {
    font-size: 24px;
  }
  .HeroSection .c-container .phone-input {
    border-radius: 10px;
    width: 300px;
    text-align: center;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    font-size: 22px;
    font-family: 'CenturyGothicBold';
    background-color: #fff;
    font-weight: bold;
    height: 52px;
  }
  .HeroSection .c-container .phone-input:hover {
    border-width: 0px;
  }
  .HeroSection .c-container .phone-input:focus {
    border-width: 0px;
  }
  .HeroSection .c-container .phone-input .ant-input {
    border-radius: 10px;
    border: 0px;
  }
  .HeroSection .c-container .otp-input {
    width: 52px !important;
    height: 52px;
    border-radius: 10px;
    border: 0px;
    color: black;
  }
  .HeroSection .c-container .otp-input:focus {
    outline: 0px;
  }
  .HeroSection .c-container .separator {
    width: 2rem;
    font-size: 18px;
  }
  .HeroSection .c-container .btn {
    width: 300px;
    text-align: center;
    border-radius: 10px;
    border-width: 0px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    font-family: 'CenturyGothicBold';
    border-color: rgba(255, 255, 255, 0);
    background-color: #ff78a5;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    height: 52px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .HeroSection .c-container .btn:active {
    opacity: 1;
  }
  .HeroSection .c-container .c-sub-heading1 {
    width: 427px;
    font-size: 14px;
  }
}
.login__collapse__container {
  min-width: 400px;
  position: absolute;
  top: 100px;
  left: 20px;
}
.login__collapse__container .ant-collapse-item.detail-panel {
  border-radius: 15px;
}
.login__collapse__container .detail-panel-header-subtitle {
  text-align: left !important;
}
@media screen and (max-width: 992px) {
  .login__collapse__container {
    position: static;
    margin: 0 auto;
  }
}
