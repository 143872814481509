@import '/base.scss';
.genderContainer {
  width: 30%;
  margin: 1.5rem 0;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
  }
  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    background: white;

    margin: 0 10px;
    border: 0px;

    &.editable {
      cursor: pointer;
    }
    &:hover {
      color: $base-color;
    }

    .button__icon {
      padding: 15px 25px;
      box-shadow: $box-shadow;

      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: #ffffff;
      border: $border-solid;
    }
  }
}
.text-pink {
  color: $base-color;
}
