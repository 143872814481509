.label {
  font-family: 'Lato';
  font-size: 0.875rem;
  font-weight: 700;
  display: block;
}

.ant-input.input {
  border: 0;
  border-bottom: 1px solid gray;
  padding: 0;
  margin-top: 0.625rem;
}

.ant-input:focus {
  box-shadow: none;
  border: 1px solid #d9d9d9;
}
