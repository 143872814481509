@mixin disabled-button {
  background-color: #ffc5da;
  color: white;
  border: 0;
}

.button {
  width: 240px;
  font-family: 'CenturyGothicBold';
  &.link {
    font-family: 'Lato';
    &.see-more-button {
      padding-left: 4px;
      padding-top: 0;
      margin-top: -6px;
    }
  }
  &.save {
    font-family: 'CenturyGothicBold';
  }
  &:disabled {
    @include disabled-button;
    &:hover {
      @include disabled-button;
    }
  }
}
