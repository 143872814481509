@import '/base.scss';
.imageInput {
  &__wrapper {
    position: relative;
    background-color: #fff;
    border: $border-solid;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: $box-shadow;
    padding: 6px;
    width: 100px;
    height: 100px;
  }
  &__inner {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  &__dropzone {
    display: grid;

    border: 1.2px dashed $base-color;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    place-items: center;
    .loader {
      z-index: 99;
      color: $base-color;
    }
  }
  &__image {
    position: absolute;
    inset: 0;
    box-shadow: $box-shadow;
    img {
      // max-width: 100%;
      // height: auto;
      max-width: 100%;
      // height: 100%;
      object-fit: cover;
    }
  }
  &__delete {
    position: absolute;
    z-index: 10;
    padding: 0.25rem;
    width: 1.9rem;
    height: 1.9rem;
    display: grid;
    place-items: center;

    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    bottom: 0.3rem;
    right: 0.3rem;
    border: 0;
    box-shadow: $box-shadow;
  }
}
