.collapsible-container {
  background-color: white;

  .ant-collapse-item,
  .ant-collapse-item:last-child {
    padding: 0.75rem;
    margin-bottom: 0.625rem;
    background-color: #f5f5f5;
    border-radius: 1rem;
    border: 0;
  }
  .ant-collapse-header {
    font-size: 24px;
    font-family: 'CenturyGothicBold';

    .anticon.expandIcon {
      font-size: 1.25rem;
    }
  }
  .panelBody {
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;
  }
  p {
    padding: 0;
  }

  // This is currently only being used for showing subtitle in Partner Preference section
  .header-container {
    p {
      margin: 0;
    }

    .header-subtitle {
      font-size: 1rem;
      font-family: 'Lato';
    }
  }
}
