.images {
  border-radius: 18px;
  padding: 15px;
  width: 200px;
  height: auto;
}

.imageSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.additionalinfo-row {
  padding-top: 5px;
  padding-bottom: 5px;
}

.accountDetailsHeader {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }

  .completeProfile {
    font-size: 1.125rem;
    color: #ff5b91;
    font-weight: 400;
    margin-left: 2rem;
  }
}
.verifyButtonWrapper {
  display: flex;
  margin-left: auto;
}
