.ant-message-notice-content {
  z-index: 10000000;
}

.crop_container {
  max-width: 1024px;
  width: 100vh;
  height: calc(100vh - 140px);
}

.slider_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 50px;
  width: 100vh;
}
